.footer {
    background-image: linear-gradient(135deg, #ce4be8 0%, #207ce5 100%);
    padding: 6rem 10rem;
    color: #fff;
}

.footer h4 {
    margin-bottom: 2rem;
    font-size: 1.3rem;
}

.footer p {
    line-height: 1.8;
    width: 75%;
}

.social-icons-box a {
    color: #fff;
}

.social-icons-box i {
    font-size: 1.3rem;
    margin-right: 1rem;
    background-color: #24d2c8;
    padding: 0.8rem 0.9rem;
    border-radius: 50%;
}

ul {
    list-style: none;
    margin-left: -2rem;
}

ul li {
    margin-bottom: 1rem;
}

.flex-box {
    display: flex;
}

.flex-box i {
    font-size: 1.3rem;
    margin-top: 0.2rem;
    margin-right: 2rem;
    color: #24d2c8;
}

@media only screen and (max-width: 769px) {
    .footer {
        padding: 5rem 7rem;
    }

    .col {
        margin: 2rem auto;
    }
}

@media only screen and (max-width: 426px) {
    .footer {
        padding: 2rem 5rem;
    }
}

@supports (-webkit-touch-callout: none) {
    .footer {
        padding: 4rem 7rem;
    }
}