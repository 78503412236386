.card-box {
    padding: 0;
    margin: 1rem;
    border: none;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
}

.card-box:hover {
    transform: translateY(-3%);
}

.card-box:hover .card-title {
    color: #4986FC;
}

.img-box {
    height: 200px;
    width: 288px;
    overflow: hidden;
}

.img-fluid {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: all 0.5s ease;
}

.card-box:hover .img-box .img-fluid {
    transform: scale(1.2);
}

.tag {
    color: #fff;
    background-color: #4986FC;
    width: 50%;
    padding: 0.3rem;
    text-align: center;
}

.card-body {
    padding: 1.5rem;
    text-align: left !important;
}

.card-title {
    text-align: left !important;
    /* margin-bottom: 1rem; */
    font-size: 1.3rem;
}

.class-body .caption {
    font-weight: 500;
}

.class-body .caption span {
    color: #999;
    font-weight: 600;
}

.card-text-box {
    /* margin-top: 2rem; */
}

.stars {
    text-align: left !important;
    border-top: 1px solid #99999949;
    padding-top: 1rem;
}

.star {
    color: #FF7B2B;
    margin-right: 0.3rem;
    margin-bottom: 0.7rem !important;
}

.stars p span {
    color: #FF7B2B;
}